import classnames from "classnames";
import styles from "./main.module.scss";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const Main = (props: Props) => {
  const { className, children } = props;
  const classes = classnames(styles.main, "pro-cycling-page", className);

  return <main className={classes}>{children}</main>;
};

export default Main;
