import React from "react";
import classnames from "classnames";
import Components from "@/components/components";
import Main from "@/components/Layout/main";
import StoryCards from "@/components/StoryCards/story-cards";
import styles from "./home.module.scss";
import Button from "@/components/Button/button";
import Section from "@/components/Section/section";
import FormNewsletter from "@/components/FormNewsletter/form-newsletter";
import { GetBlogStories } from "@/lib/storyblok-gapi";
import { HomeStoryblok } from "@/types/component-types-sb";

interface Props {
  latestBlogStories: GetBlogStories["BlogItems"]["items"];
  content: HomeStoryblok;
}

const HomePage = (props: Props) => {
  const { content, latestBlogStories } = props;
  const classes = classnames("homepage");

  return (
    <Main className={classes}>
      {content?.body?.map((blok) => {
        return React.createElement(Components(blok.component), {
          key: blok._uid,
          blok,
        });
      })}
      <Section className={styles.blogSectionWrap}>
        <StoryCards
          title="Stories from the peloton"
          stories={latestBlogStories}
          storiesPerRow={["1", "3"]}
          layout={["3", "3"]}
          aspect={["3/2", "3/2"]}
          className={styles.blogSection}
          sort={"desc"}
        />
        <div className={styles.blogButton}>
          <Button
            link={"/blog"}
            size={"medium"}
            icon={"IconArrowRight"}
            btnStyle={"black"}
          >
            Read our blog
          </Button>
        </div>
        <FormNewsletter />
      </Section>
    </Main>
  );
};

export default HomePage;
