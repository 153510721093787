import React, { forwardRef, useContext } from "react";
import { SettingsContext } from "@/context/SettingsContext";
import s from "./newsletter.module.scss";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import * as gtag from "@/lib/gtag";

interface Props {
  className?: string;
}

const JoinNewsletterForm = (props: Props) => {
  const { className } = props;
  const { settings } = useContext(SettingsContext);

  const {
    content: {
      newsletter_sign_up_text = "",
      newsletter_success_text = "",
    } = {},
  } = settings;

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors, touchedFields, isSubmitSuccessful },
  } = useForm({ mode: "onBlur" });

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    try {
      gtag.event({
        action: `User submitted Form Newsletter`,
        category: "Newsletter",
        label: `Newsletter-Signup`,
        value: true,
      });
      // Forced to use fetch, zapier doesn't allow for content-type header which axios hardcodes
      // Having content-type header triggers cors
      await fetch("https://hooks.zapier.com/hooks/catch/10173392/bohn8zz/", {
        method: "POST",
        headers: {
          accept: "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      setError(
        "email",
        {
          type: "focus",
          message: "Something went wrong, please try again later",
        },
        { shouldFocus: true }
      );
      console.error(error);
      throw error;
    }
  };

  const classes = classnames(s.wrapper, className);

  return (
    <div className={classnames(s.outer, "layout-grid")}>
      <div className={classes}>
        <div className={s.formCopy}>
          <h2 className="ef-h5 u-mb-0 -inv">
            {!isSubmitSuccessful
              ? newsletter_sign_up_text
              : `${newsletter_success_text} ${getValues("email")}`}
          </h2>
        </div>
        <div className={s.formForm}>
          {!isSubmitSuccessful ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={s.formInput}>
                <TextInput
                  touched={touchedFields.email}
                  valid={!errors.email && touchedFields.email}
                  type="email"
                  placeholder="Email"
                  className={s.formTextInput}
                  {...register("email", {
                    required: true,
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "Please enter a valid email",
                    },
                  })}
                />
                <button className={s.formButton} type="submit">
                  Subscribe
                </button>
              </div>
              {errors.email?.message ? (
                <span className={s.errorMessage}>{errors.email?.message}</span>
              ) : null}
            </form>
          ) : null}
        </div>
      </div>
    </div>
  );
};

interface TextInputProps {
  touched: boolean;
  valid: boolean;
  invalid?: boolean;
  className?: string;
  type: string;
  placeholder: string;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const { valid, invalid, className, ...rest } = props;
  const classnamesWrapper = classnames(
    "ef-input-w",
    `${valid ? "-is-valid" : ""}`,
    `${invalid ? "-is-invalid" : ""}`,
    className
  );
  return (
    <div className={classnamesWrapper}>
      <input ref={ref} className="ef-input" {...rest} />
    </div>
  );
});

export default JoinNewsletterForm;
